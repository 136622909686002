import { Feature, Polygon, Position } from "geojson";
import { coordAll } from "@turf/meta";
import bboxPolygon from "@turf/bbox-polygon";
import bbox from "@turf/bbox";

import { GEOJSON_TYPES } from "../consts/editor";

import { gte } from "./functional/relation";
import { apply, pipe } from "./functional/function";
import { defaults, prop } from "./functional/object";
import { divide, subtract } from "./functional/math";
import { head, juxt, last, map } from "./functional/list";
import { generateId } from "./string";
import { getPolygonHoles } from "./editor";
import { cleanDuplicates } from "./data";

const DRAG_RELEVANCY_THRESHOLD = 10;

export const getTwoClickPolygon = (
  coord1: Position,
  coord2: Position
): Feature<Polygon> => {
  const rectangle = bboxPolygon([coord1[0], coord1[1], coord2[0], coord2[1]]);
  rectangle.properties = rectangle.properties || {};
  rectangle.properties.shape = "Rectangle";

  return rectangle;
};

export const getOppositePolygonCorners: (
  coords: Position[]
) => [Position, Position] = juxt([head, prop("2")]);

export const calculateDistanceRankingValue = (
  p1: Position,
  p2: Position
): number => {
  const [x1, y1] = p1;
  const [x2, y2] = p2;

  return Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2);
};

export const calculateDistance = (p1: Position, p2: Position): number =>
  Math.sqrt(calculateDistanceRankingValue(p1, p2));

// We use the calculateDistanceRankingValue here, because it doesn't do
// the expensive Math.sqrt operation; that's why we compare the outcome
// with the threshold to the power of two.
export const isDragRelevant = (coordinates: [Position, Position]): boolean =>
  pipe(
    map(defaults([])),
    apply(calculateDistanceRankingValue),
    gte(Math.pow(DRAG_RELEVANCY_THRESHOLD, 2))
  )(coordinates);

export const getFeatureWidth = (feature: Feature, ratio = 1) =>
  pipe(
    coordAll,
    map(head),
    juxt([apply(Math.min), apply(Math.max)]),
    apply(subtract),
    divide(ratio),
    Math.round
  )(feature);

export const getFeatureHeight = (feature: Feature, ratio = 1) =>
  pipe(
    coordAll,
    map(last),
    juxt([apply(Math.min), apply(Math.max)]),
    apply(subtract),
    divide(ratio),
    Math.round
  )(feature);

export function getMarkupFeatureContextProps(feature: any, deck: any) {
  if (!deck) return null;

  const featureCoords = bboxPolygon(bbox(feature)).geometry.coordinates[0];

  const minX = Math.min(...featureCoords.map((c: any) => c[0]));
  const maxY = Math.max(...featureCoords.map((c: any) => c[1]));

  if (deck?.viewManager?._viewports?.length > 0) {
    const [left, top] = deck.viewManager._viewports[0].project([minX, maxY]);

    return {
      left,
      top,
      isMarkup: true,
      selected: [feature],
      selections: [feature.properties.id],
    };
  }

  return null;
}

function getDistance(point1: any, point2: any) {
  const [x1, y1] = point1;
  const [x2, y2] = point2;
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

function getMergePoints(line1: any, line2: any) {
  const line1Start = line1[0];
  const line1End = line1[line1.length - 1];

  const line2Start = line2[0];
  const line2End = line2[line2.length - 1];

  const distance1 = getDistance(line1Start, line2Start);
  const distance2 = getDistance(line1Start, line2End);
  const distance3 = getDistance(line1End, line2Start);
  const distance4 = getDistance(line1End, line2End);

  const minDistance = Math.min(distance1, distance2, distance3, distance4);

  if (minDistance === distance1) return [minDistance, 0, 0];
  if (minDistance === distance2) return [minDistance, 0, line2.length - 1];
  if (minDistance === distance3) return [minDistance, line1.length - 1, 0];
  if (minDistance === distance4)
    return [minDistance, line1.length - 1, line2.length - 1];
}

function mergeLines(
  line1Coords: any,
  line2Coords: any,
  minDistance: number,
  line1PIndex: number,
  line2PIndex: number
): any {
  const newCoords = [];

  const line1Point = line1Coords[line1PIndex];
  const line2Point = line2Coords[line2PIndex];
  const midPoint = [
    (line1Point[0] + line2Point[0]) / 2,
    (line1Point[1] + line2Point[1]) / 2,
  ];

  if (line1PIndex === 0) {
    if (line2PIndex === 0) {
      for (let i = line2Coords.length - 1; i >= 0; i--) {
        if (i === line2PIndex) {
          if (minDistance > 40) {
            newCoords.push(line2Coords[i]);
          }
        } else {
          newCoords.push(line2Coords[i]);
        }
      }
    } else {
      for (const pointIdx in line2Coords) {
        if (parseInt(pointIdx) === line2PIndex) {
          if (minDistance > 40) {
            newCoords.push(line2Coords[pointIdx]);
          }
        } else {
          newCoords.push(line2Coords[pointIdx]);
        }
      }
    }
  }

  for (const pointIdx in line1Coords) {
    if (parseInt(pointIdx) === line1PIndex) {
      if (minDistance <= 40) {
        newCoords.push(midPoint);
      } else {
        newCoords.push(line1Coords[pointIdx]);
      }
    } else {
      newCoords.push(line1Coords[pointIdx]);
    }
  }

  if (line1PIndex !== 0) {
    if (line2PIndex === 0) {
      for (const pointIdx in line2Coords) {
        if (parseInt(pointIdx) === line2PIndex) {
          if (minDistance > 40) {
            newCoords.push(line2Coords[pointIdx]);
          }
        } else {
          newCoords.push(line2Coords[pointIdx]);
        }
      }
    } else {
      for (let i = line2Coords.length - 1; i >= 0; i--) {
        if (i === line2PIndex) {
          if (minDistance > 40) {
            newCoords.push(line2Coords[i]);
          }
        } else {
          newCoords.push(line2Coords[i]);
        }
      }
    }
  }

  return newCoords;
}

export function mergeLinesCoordinates(line1: any, line2: any): any {
  let mergedLine: any = null;

  const line1Coords = line1.geometry.coordinates;
  const line2Coords = line2.geometry.coordinates;

  const [minDistance, line1Index, line2Index] = getMergePoints(
    line1Coords,
    line2Coords
  );
  const mergedLineCoords = mergeLines(
    line1Coords,
    line2Coords,
    minDistance,
    line1Index,
    line2Index
  );

  mergedLine = {
    ...line1,
    geometry: {
      ...line1.geometry,
      coordinates: mergedLineCoords,
    },
    properties: {
      ...line1.properties,
      id: generateId(),
    },
  };

  return mergedLine;
}

export function combineLines(lines: any): any {
  if (lines.length === 0) {
    return;
  }

  if (lines.length === 1) {
    return lines[0];
  }

  let mergedLine = lines[0];
  const mergedLineCoords = mergedLine.geometry.coordinates;
  const lineStart = mergedLineCoords[0];
  const lineEnd = mergedLineCoords[mergedLineCoords.length - 1];

  let filteredLines = lines.slice(1);

  let closestLine: any = null;
  let closesetLineDistance = Infinity;

  for (const neighbour of lines) {
    if (neighbour.properties.id !== mergedLine.properties.id) {
      const neighbourCoords = neighbour.geometry.coordinates;
      const neighbourStart = neighbourCoords[0];
      const neighbourEnd = neighbourCoords[neighbourCoords.length - 1];

      const d1 = getDistance(lineStart, neighbourStart);
      const d2 = getDistance(lineStart, neighbourEnd);
      const d3 = getDistance(lineEnd, neighbourStart);
      const d4 = getDistance(lineEnd, neighbourEnd);

      const minDistance = Math.min(d1, d2, d3, d4);

      if (minDistance < closesetLineDistance) {
        closesetLineDistance = minDistance;
        closestLine = neighbour;
      }

      if (minDistance === 10) break;
    }
  }

  if (closestLine) {
    mergedLine = mergeLinesCoordinates(mergedLine, closestLine);
    filteredLines = filteredLines.filter(
      (line: any) => line.properties.id !== closestLine.properties.id
    );
  }

  if (filteredLines.length > 0) {
    return combineLines([mergedLine, ...filteredLines]);
  } else {
    return mergedLine;
  }
}

export function getLinesNeighbours(lines: any): any {
  // combineLines(filteredlines);
}

export function getFeaturesForAtomicOperations(
  features: any[],
  { addFeatures, editFeatures, deleteFeatures }: any
) {
  const addFeaturesWithHoles = getPolygonHoles(addFeatures || []);
  const editFeaturesWithHoles = getPolygonHoles(editFeatures || []);

  const deleteFeaturesIdsSet = new Set(
    [
      ...(deleteFeatures || []),

      ...(addFeaturesWithHoles || []),
      ...(editFeaturesWithHoles || []),
    ].map((feature: any) => feature.properties.id)
  );

  const filteredFeatures = features.filter(
    (feature: any) =>
      !deleteFeaturesIdsSet.has(feature.properties.id) &&
      !deleteFeaturesIdsSet.has(feature.properties.parentId)
  );

  const newFeatures = cleanDuplicates([
    ...(addFeaturesWithHoles || []),
    ...(editFeaturesWithHoles || []),
  ]);

  filteredFeatures.push(...newFeatures);

  return filteredFeatures;
}

export function getAtomicOperationsDiff(geoJson: any[], oldGeoJson: any[]) {
  const newFeaturesIdsSet = new Set(
    geoJson.map((feature) => feature.properties.id)
  );
  const oldFeaturesMap = new Map(
    oldGeoJson.map((feature) => [feature.properties.id, feature])
  );

  const addFeatures = [];
  const editFeatures = [];
  const deleteFeatures = oldGeoJson.filter(
    (feature) => !newFeaturesIdsSet.has(feature.properties.id)
  );

  for (const feature of geoJson) {
    if (!oldFeaturesMap.has(feature.properties.id)) {
      addFeatures.push(feature);
    } else {
      editFeatures.push(feature);
    }
  }

  return { addFeatures, editFeatures, deleteFeatures };
}
