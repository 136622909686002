import { BitmapLayer, PathLayer, TextLayer } from "@deck.gl/layers";
import bbox from "@turf/bbox";
import center from "@turf/center";
import window from "global/window";
import bboxPolygon from "@turf/bbox-polygon";
import booleanWithin from "@turf/boolean-within";

import {
  IClassification,
  IClassificationMap,
  RgbaRepresentation,
} from "../types/editor";

import {
  IFilter,
  MODES_IDS,
  HOLE_TYPE,
  DEFAULT_COLOR,
  DEFAULT_SHAPE,
  GEOJSON_TYPES,
  DEFAULT_MARKUP_COLOR,
  COORDINATE_NEST_LEVEL,
  GEOJSON_TYPES_CLASSIFICATIONS,
} from "../consts/editor";

import {
  Point,
  Feature,
  Polygon,
  Position,
  LineString,
  MultiPoint,
  MultiPolygon,
  MultiLineString,
  FeatureCollection,
  GeometryCollection,
} from "geojson";

import { SHAPES } from "../consts/svg";
import { getValueByKey } from "./modes";
import { generateId, prettyInches } from "./string";
import SelectionLayer from "../modes/SelectionLayer";
import { getBounds, cleanCoordinates, cleanEmptyFeatures } from "./coordinates";
import { FeatureTypesWithCoordinates } from "../modes/types";
import { DEFAULT_MARKUP_TEXT_OPTIONS } from "../modes/editor-modes";
import EditableGeojsonLayer from "../modes/base/EditableGeojsonLayer";
import { getFeatureMeasures } from "../modes/base/ImmutableLayersData";
import { getFeatureGroupColor, getFillColor, getLineColor } from "./rendering";

interface IFeatureWithIndex extends Feature {
  index: number;
}

interface PASTE_PROPS {
  clMap?: any;
  coords: Position;
  isHole?: boolean;
  inplace?: boolean;
  clipBoardData: any;
  isPasteContinuous?: boolean;
  filteredtakeOffTypes: string[];
  geoJson: Feature<FeatureTypesWithCoordinates>[];
  copiedFeatures: Feature<FeatureTypesWithCoordinates>[];
}

const cache = new Map();
const last = new Map();

let isRunning: boolean = false;
let dataTimeStamp: number = null;

export function focusEditor() {
  const canvas = document.getElementById("geojson");

  if (canvas) {
    canvas.setAttribute("tabindex", "0");
    canvas.focus();
  }
}

export function isCoordsOutOfBounds(
  coords: any,
  minX: number,
  minY: number,
  maxX: number,
  maxY: number
): boolean {
  let isCoordOutOfBounds = false;

  if (Array.isArray(coords[0])) {
    coords.map(function checkCoord(coord: any): any {
      if (!Array.isArray(coord[0])) {
        const check =
          coord[0] >= minX &&
          coord[0] <= maxX &&
          coord[1] >= minY &&
          coord[1] <= maxY;
        if (!check) {
          isCoordOutOfBounds = true;
        }
        return coord;
      } else {
        return (coord as Position).map(checkCoord);
      }
    });
  } else {
    const check =
      coords[0] >= minX &&
      coords[0] <= maxX &&
      coords[1] >= minY &&
      coords[1] <= maxY;
    if (!check) {
      isCoordOutOfBounds = true;
    }
  }

  return isCoordOutOfBounds;
}

export function validateGeometry(
  type: string,
  coordinates: Position[][][] | Position[][] | Position[] | Position | number
): boolean {
  let nestLevel: number = COORDINATE_NEST_LEVEL[type];

  while (coordinates && --nestLevel > 0) {
    coordinates = (coordinates as Position)[0];
  }

  return coordinates && Number.isFinite((coordinates as Position)[0]);
}

export function calcZoom(
  width: number,
  height: number,
  padding: number,
  innerHeight: number = null
): number {
  innerHeight = innerHeight || window.innerHeight;

  const pixelRatio = 2;
  const heightRatio =
    Math.log((height + padding * height) / (innerHeight * pixelRatio)) /
    Math.log(2);

  return -heightRatio - 1;
}

export function prepareLayersData(
  props: any,
  state: any,
  refDeckgl: any,
  handlePicking: (arg: any) => void,
  onEdit: (args?: any) => void,
  skipCache: boolean = false
) {
  if (!skipCache && isRunning && cache.has("prepareLayersData")) {
    isRunning = false;
    return cache.get("prepareLayersData");
  }
  isRunning = true;

  const { view, mode, ready, hidden, refresh, allSelections, shouldRefresh } =
    props;
  let filteredLayers = props.filteredLayers;

  // const step = allSelections.length > 100 ? 1000 / 30 : 1000 / 60;

  if (dataTimeStamp === null) {
    dataTimeStamp = performance.now();
  }

  // Needs improvement
  // if (
  //   !skipCache &&
  //   cache.has("mode") &&
  //   imagesCache.has(view.id) &&
  //   cache.get("mode") === mode.id &&
  //   cache.has("prepareLayersData") &&
  //   performance.now() - dataTimeStamp < step
  // ) {
  //   return cache.get("prepareLayersData");
  // }

  const lastID = last.has("viewID") ? last.get("viewID") : "";
  const lastHidden = last.has("hiddenLayers") ? last.get("hiddenLayers") : [];
  const lastSelected = last.has("allSelections")
    ? last.get("allSelections")
    : [];

  const isSamePage = !shouldRefresh && !refresh && lastID === view.id;
  const isSameHidden = isSamePage && lastHidden === hidden;
  const isSameSelections = lastSelected === allSelections;

  filteredLayers =
    cache.has("filteredLayers") && isSameHidden
      ? cache.get("filteredLayers")
      : hidden.length > 0
      ? filteredLayers.filter(
          (fe: Feature) => !hidden.includes(fe.properties.id)
        )
      : filteredLayers;

  const visibleLayers = ready ? filteredLayers : [];

  const selectedLayers =
    isSameSelections && cache.has("selectedLayers")
      ? cache.get("selectedLayers")
      : visibleLayers
          .map(
            (fe: Feature, idx: number): IFeatureWithIndex => ({
              ...fe,
              index: idx,
            })
          )
          .filter((fe: IFeatureWithIndex) =>
            allSelections.includes(fe.properties.id)
          )
          .map((fe: IFeatureWithIndex): number => fe.index);

  const dimentionLines = visibleLayers
    ?.filter((f: Feature) =>
      f.properties?.types?.includes(GEOJSON_TYPES.dimensionLine)
    )
    .map((fe: Feature<LineString>) => ({
      ...fe,
      properties: {
        ...fe.properties,
        perimeter:
          calcDistanceNL(
            view,
            fe.geometry.coordinates[0],
            fe.geometry.coordinates[1]
          ) || 0,
      },
    }));

  const wallsData = visibleLayers.filter(
    (fe: Feature) =>
      fe.geometry?.type === GEOJSON_TYPES.LineString &&
      !fe.properties.types?.includes(GEOJSON_TYPES.dimensionLine)
  );

  const wallsGroupsData = visibleLayers
    .filter(
      (fe: Feature) =>
        fe.geometry?.type === GEOJSON_TYPES.MultiLineString &&
        !fe.properties.types?.includes(GEOJSON_TYPES.dimensionLine)
    )
    .map((fe: Feature<MultiLineString>): Feature<LineString>[] => {
      const lines: Feature<LineString>[] = [];

      fe.geometry.coordinates.forEach((line) => {
        lines.push({
          ...fe,
          geometry: {
            type: GEOJSON_TYPES.LineString,
            coordinates: line,
          },
        });
      });
      return lines;
    })
    .flat();

  last.set("viewID", view.id);
  last.set("hiddenLayers", hidden);
  last.set("allSelections", allSelections);

  const results = renderLayers(
    props,
    state,
    visibleLayers,
    selectedLayers,
    wallsData,
    wallsGroupsData,
    dimentionLines,
    refDeckgl,
    handlePicking,
    onEdit
  );

  if (!skipCache) {
    cache.set("filteredLayers", filteredLayers);
    cache.set("selectedLayers", selectedLayers);
    cache.set("prepareLayersData", results);
    cache.set("mode", mode.id);
  }

  dataTimeStamp = performance.now();
  isRunning = false;
  return results;
}

export function calcDistanceNL(view: any, p1: Position, p2: Position) {
  const width = view.url_width;
  const bounds = view.bounds || [
    [0, 0],
    [view.url_width, view.url_height],
  ];
  const scale_real = view.scale_real;
  const scale_drawing = view.scale_drawing;
  const dpi = view.url_dpi;

  const pixelDistance = calculatePixelDistanceRefNL(p1, p2, width, bounds);

  return pixelDistance * (1 / dpi) * (scale_real / scale_drawing);
}

export function calculatePixelDistanceRefNL(
  p1: Position,
  p2: Position,
  width: number,
  bounds: Position[]
) {
  const is3D = p1.length === 3 && p2.length === 3;

  const distance = is3D
    ? Math.hypot(p2[0] - p1[0], p2[1] - p1[1], p2[2] - p1[2])
    : Math.hypot(p2[0] - p1[0], p2[1] - p1[1]);

  const imageToBoundsRatio =
    width / (bounds && bounds[1] && bounds[1][0] ? bounds[1][0] : 1);
  return distance * imageToBoundsRatio;
}

const getIsSelected = (feature: any, selections: string[]) => {
  const fe = feature?.__source?.object ? feature?.__source?.object : feature;
  return selections.includes(fe.properties.id) ? 1 : 0;
};

export const getFeatureGroupShape = (
  feature: any,
  group: any,
  groupClMap: Record<string, IClassification>
) => {
  const key = getValueByKey(feature, group.key);

  let shape = null;
  if (Array.isArray(key)) {
    for (const k of key) {
      if (groupClMap[k]) {
        shape = groupClMap[k].shape;
        break;
      }
    }
    if (!shape) {
      shape = DEFAULT_SHAPE;
    }
  } else {
    if (groupClMap[key]) {
      shape = groupClMap[key].shape;
    } else {
      shape = DEFAULT_SHAPE;
    }
  }
  return shape;
};

const getIcon = (
  feature: any,
  groupFilter: IFilter,
  groupClMap: Record<string, IClassification>
) => {
  const fe = feature?.__source?.object ? feature?.__source?.object : feature;
  return getFeatureGroupShape(fe, groupFilter, groupClMap);
};

export function renderLayers(
  props: any,
  state: any,
  visibleLayers: Feature[],
  selectedLayers: Feature[],
  wallsData: any,
  wallsGroupsData: any,
  dimentionLines: Feature[],
  refDeckgl: any,
  handlePicking: (e: any) => void,
  onEdit: () => void
) {
  const {
    mode,
    view,
    geoJson,
    isLabeler,
    groupCLMap,
    imagesCache,
    groupFilter,
    allSelections,
    editorSettings,
    useSelectionBox,
    isCalibratingScale,
    filteredtakeOffTypes,
  } = props;
  const { vState, hovered } = state;

  const selectionLayer = new SelectionLayer({
    id: "selection",
    lineWidthMinPixels: 2,
    layerIds: ["geojson"],
    selectionType: "rectangle",
    onSelect: (e: any) => handlePicking(e),
    getTentativeLineDashArray: [10, 10],
    getTentativeFillColor: [0, 0, 0, 40],
    getTentativeLineColor: [0, 0, 0],
    lineWidthMaxPixels: 2,
  } as any);

  const getIconFromState = (feature: Feature) =>
    Object.values(SHAPES).indexOf(getIcon(feature, groupFilter, groupCLMap));

  const getShapeRotation = (feature: Feature) =>
    feature?.properties?.rotation || 0;

  const getIsSelectedFromState = (feature: Feature) =>
    getIsSelected(feature, allSelections);

  const editableGeoJsonLayer = new EditableGeojsonLayer({
    mode: mode.mode,
    modeConfig: {
      ...mode.config,
      modeId: mode.id,
      viewport: {
        ...vState,
        height: window.innerHeight,
        width: window.innerWidth,
      },
      view,
      geoJson,
      isLabeler,
      deck: refDeckgl,
      isCalibratingScale,
      filteredtakeOffTypes,
      calcDistance: calcDistanceNL,
      useSelectionBox,
      isSnapOn: editorSettings.isSnapOn,
      useMetrics: editorSettings.useMetrics,
      snapDistance: editorSettings.snapDistance,
    },
    id: "geojson",
    pickingDepth: 1,
    pickingRadius: 10,
    onEdit,
    autoHighlight: false,
    data: {
      type: GEOJSON_TYPES.FeatureCollection,
      features: visibleLayers,
    },
    pointRadiusMinPixels: 6,
    pointRadiusMaxPixels: 12,
    editHandlePointRadiusScale: 2,
    editHandlePointRadius: (handle: Feature) =>
      handle.properties.editHandleType === "intermediate" ? 6 : 12,
    selectedFeatureIndexes: selectedLayers,
    parameters: {
      depthTest: true,
      depthMask: false,
      blend: true,
    },
    getPointRadius: () => editorSettings.pointSize,
    getLineWidth: () => editorSettings.lineWidth,
    getPolygonOpacity: () => editorSettings.polygonOpacity,
    getFillColor: (feature: Feature) =>
      getFillColor(
        feature,
        allSelections,
        groupFilter,
        groupCLMap,
        editorSettings.polygonOpacity
      ),
    getLineColor: (feature: Feature) =>
      getLineColor(feature, hovered, allSelections),
    getShape: getIconFromState,
    getRotation: getShapeRotation,
    getSelected: getIsSelectedFromState,
    getEditHandlePointColor: (handle: Feature) =>
      handle.properties.editHandleType === "intermediate"
        ? [150, 160, 150, 250]
        : [0, 0, 0, 250],
    _subLayerProps: {
      geojson: {
        filled: true,
        stroked: true,
        getLineWidth: (fe: Feature) =>
          fe.properties.types.includes(GEOJSON_TYPES.markup)
            ? fe.properties?.thinkness
            : editorSettings.lineWidth,
        extruded: false,
        wireframe: false,
        getElevation: () => 0,
        pointRadiusScale: 4,
        pointRadiusMinPixels: 4,
        pointRadiusMaxPixels: 1000,
      },
      guides: {
        getFillColor: (fe: Feature) =>
          fe.properties.guideType === GEOJSON_TYPES.markup
            ? [
                ...mode?.config?.options?.color?.default?.fill,
                (mode?.config?.options?.opacity?.default * 250) / 100,
              ] || [250, 50, 50, 180]
            : fe.properties.guideType === "error"
            ? [250, 50, 50, 180]
            : [0, 0, 200, 180],
        getLineColor: (fe: Feature) =>
          fe.properties.guideType === GEOJSON_TYPES.markup
            ? [
                ...mode?.config?.options?.color?.default?.fill,
                mode?.config?.options?.thinkness?.default === 0 ? 0 : 240,
              ] || [250, 50, 50, 180]
            : fe.properties.guideType === "error"
            ? [250, 50, 50, 240]
            : [0, 0, 200, 240],
        getLineWidth: (fe: Feature) =>
          fe.properties.guideType === GEOJSON_TYPES.markup
            ? mode?.config?.options?.thinkness?.default
            : editorSettings.lineWidth,
      },
      tooltips: {
        getSize: 14,
        getColor: [255, 255, 255, 255],
        backgroundColor: [0, 0, 0, 255],
        lineHeight: 1.0,
        backgroundPadding: [1, 1],
        outlineColor: [255, 0, 0, 255],
        getPixelOffset: [0, -12],
      },
    },
  } as any);

  const wallsLayer = new PathLayer({
    id: "togal-walls-layer",
    data: [...wallsData, ...wallsGroupsData],
    pickable: true,
    widthScale: 20,
    widthMinPixels: 2,
    getPath: (feature) =>
      feature.geometry.type === GEOJSON_TYPES.LineString
        ? feature.geometry.coordinates
        : feature.geometry.coordinates.flat(),
    getColor: (feature) =>
      [
        ...getFeatureGroupColor(feature, groupFilter, groupCLMap).fill,
        Math.round(editorSettings.polygonOpacity * 255),
      ] as RgbaRepresentation,
    getWidth: () => editorSettings.wallsThikness,
  });

  const bounds = getBounds(view.page.bounds);

  const imglayer =
    imagesCache &&
    imagesCache.has(view.page.id) &&
    imagesCache.get(view.page.id).isLoaded
      ? new BitmapLayer({
          id: "map-img",
          bounds: bounds as any,
          image: imagesCache.get(view.page.id).context,
          transparentColor: [255, 255, 255, 0],
          pickable: false,
          opacity: editorSettings.imageOpacity,
        } as any)
      : null;

  const dimensionsLayer = new TextLayer({
    id: "text-layer",
    data: dimentionLines,
    pickable: true,
    getPosition: (feature: Feature<MultiPoint>) =>
      feature.geometry.coordinates[1] as [number, number],
    getText: (feature: Feature) =>
      " " +
      prettyInches(
        feature.properties.perimeter,
        true,
        editorSettings.useMetrics
      ) +
      " ",
    getPixelOffset: [0, -12],
    getSize: 14,
    getColor: [255, 255, 255, 255],
    backgroundColor: [0, 0, 0, 255],
    lineHeight: 1.0,
    backgroundPadding: [1, 1],
    outlineColor: [255, 0, 0, 255],
    getTextAnchor: "middle",
    getAlignmentBaseline: "center",
  });

  return [
    selectionLayer,
    editableGeoJsonLayer,
    wallsLayer,
    imglayer,
    dimensionsLayer,
  ];
}

function getCoordinatesDump(
  gj:
    | Point
    | LineString
    | MultiPoint
    | MultiLineString
    | Polygon
    | MultiPolygon
    | Feature
    | FeatureCollection
    | GeometryCollection
): Position[] {
  let coords;
  if (gj.type === GEOJSON_TYPES.Point) {
    coords = [gj.coordinates];
  } else if (
    gj.type === GEOJSON_TYPES.LineString ||
    gj.type === GEOJSON_TYPES.MultiPoint
  ) {
    coords = gj.coordinates;
  } else if (
    gj.type === GEOJSON_TYPES.Polygon ||
    gj.type === GEOJSON_TYPES.MultiLineString
  ) {
    coords = gj.coordinates.reduce(function (dump, part) {
      return dump.concat(part);
    }, []);
  } else if (gj.type === GEOJSON_TYPES.MultiPolygon) {
    coords = gj.coordinates.reduce(function (dump, poly) {
      return dump.concat(
        poly.reduce(function (points, part) {
          return points.concat(part);
        }, [])
      );
    }, []);
  } else if (gj.type === GEOJSON_TYPES.Feature) {
    coords = getCoordinatesDump(gj.geometry);
  } else if (gj.type === "GeometryCollection") {
    coords = gj.geometries.reduce(function (dump, g) {
      return dump.concat(getCoordinatesDump(g));
    }, []);
  } else if (gj.type === GEOJSON_TYPES.FeatureCollection) {
    coords = gj.features.reduce(function (dump, f) {
      return dump.concat(getCoordinatesDump(f));
    }, []);
  }
  return coords;
}

// from https://github.com/geosquare/geojson-bbox
export function getbbox(gj: any) {
  let coords, bbox;

  coords = getCoordinatesDump(gj);
  bbox = [
    Number.POSITIVE_INFINITY,
    Number.POSITIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
    Number.NEGATIVE_INFINITY,
  ];
  return coords.reduce(function (prev, coord) {
    return [
      Math.min(coord[0], prev[0]),
      Math.min(coord[1], prev[1]),
      Math.max(coord[0], prev[2]),
      Math.max(coord[1], prev[3]),
    ];
  }, bbox);
}

export function filterFeatureTypes(feature: Feature, types: string[]) {
  return feature.properties.types.some((i: string) => types.includes(i));
}

let oldGroupByProps: string = null;
let oldGroupBycachedResults: any = null;

export function groupBy(
  features: Feature[],
  group: any,
  groupClMap: IClassificationMap
) {
  const props = JSON.stringify({
    features,
    group,
    groupClMap,
  });

  if (!oldGroupByProps) {
    oldGroupByProps = props;
  } else {
    if (oldGroupByProps === props) {
      return oldGroupBycachedResults;
    } else {
      oldGroupByProps = props;
    }
  }

  const tempGroups: Record<string, any> = {};
  const clMap = groupClMap || {};
  let result;

  switch (group.type) {
    case 1:
      features.forEach((feature) => {
        if (feature.properties.types.includes(GEOJSON_TYPES.dimensionLine))
          return;
        const groupKey = Math.ceil(
          getValueByKey(feature, group.key) / group.value
        );

        if (groupKey in tempGroups) {
          tempGroups[groupKey] = [...tempGroups[groupKey], feature];
        } else {
          tempGroups[groupKey] = [feature];
        }
      });
      result = [tempGroups, clMap];
      oldGroupBycachedResults = result;
      return result;
    case 2:
      for (const gKey of group.value) {
        tempGroups[gKey] = [];
      }

      features.forEach((feature) => {
        if (feature.properties.types.includes(GEOJSON_TYPES.dimensionLine))
          return;

        const groupVal = getValueByKey(feature, group.key);
        if (Array.isArray(groupVal)) {
          for (const gKey of group.value) {
            if (groupVal?.includes(gKey))
              tempGroups[gKey] = [...tempGroups[gKey], feature];
          }
        } else {
          if (group.value.includes(groupVal))
            tempGroups[groupVal] = [...tempGroups[groupVal], feature];
        }
      });

      result = [tempGroups, clMap];
      oldGroupBycachedResults = result;
      return result;
    default:
      features.forEach((feature) => {
        if (feature.properties.types.includes(GEOJSON_TYPES.dimensionLine))
          return;

        const groupKey = getValueByKey(feature, group.key);
        if (groupKey in tempGroups) {
          tempGroups[groupKey] = [...tempGroups[groupKey], feature];
        } else {
          tempGroups[groupKey] = [feature];
        }
      });
      result = [tempGroups, clMap];
      oldGroupBycachedResults = result;
      return result;
  }
}

export interface ICleanAndPrepareFeaturesOptions {
  page: any;
  clMap: IClassificationMap;
  takeoffType: string;
  defaultClass: string;
  forceNewID?: boolean;
  autoClassify?: boolean;
}

function doesHaveNullCoordiantes(
  coordinates: Position[] | Position[][] | Position[][][]
) {
  let doesIncludeNull = false;
  coordinates.forEach(function checkNull(coord: any) {
    if (coord === null) {
      doesIncludeNull = true;
    } else if (Array.isArray(coord)) {
      coord.forEach(checkNull);
    }
  });

  return doesIncludeNull;
}

export function cleanAndPrepareFeatures(
  features: Feature<FeatureTypesWithCoordinates>[],
  options: ICleanAndPrepareFeaturesOptions,
  dataFilter: ((feature: Feature) => boolean) | null = null,
  beforeHook:
    | ((
        feature: Feature<FeatureTypesWithCoordinates>
      ) => Feature<FeatureTypesWithCoordinates>)
    | null = null,
  afterHook:
    | ((
        feature: Feature<FeatureTypesWithCoordinates>
      ) => Feature<FeatureTypesWithCoordinates>)
    | null = null
) {
  const { page, clMap, takeoffType, defaultClass, forceNewID, autoClassify } =
    options;

  if (!features || (features.length && features.length === 0)) return [];

  let cleanedFeatures = cleanEmptyFeatures(features);

  if (dataFilter) {
    cleanedFeatures = cleanedFeatures.filter(dataFilter);
  }

  if (beforeHook) {
    cleanedFeatures = cleanedFeatures.map(beforeHook);
  }

  cleanedFeatures = cleanedFeatures.filter(
    (feature: any) => !doesHaveNullCoordiantes(feature.geometry.coordinates)
  );

  cleanedFeatures = cleanedFeatures.map(
    (data: Feature<FeatureTypesWithCoordinates>) => {
      const defaultClassName =
        data?.properties?.ocr_classification &&
        data?.properties?.ocr_classification?.length > 0
          ? "OCR_" + data?.properties?.ocr_classification[0]
          : defaultClass;

      const className = data?.properties?.className || defaultClassName;
      const cl = clMap[className];
      const highlight = data.properties.highlight || null;
      const types = data.properties.types.includes(takeoffType)
        ? data.properties.types
        : [takeoffType, ...data.properties.types];

      let coords = cleanCoordinates(
        data.geometry.type,
        data.geometry.coordinates
      );

      let updatedFeature = {
        type: "Feature",
        properties: {
          ...data.properties,
          name: data.properties.name || "",
          className,
          highlight,
          autoClassify: autoClassify || false,
          id: forceNewID ? generateId() : data.properties.id || generateId(),
          color: cl ? cl.colorStyle.hex : DEFAULT_COLOR.color,
          shape: cl ? cl.shape : DEFAULT_SHAPE,
          types,
          suggestion: data.properties.suggestion || null,
        },
        geometry: {
          type: data.geometry.type,
          coordinates: coords,
        },
      } as Feature<FeatureTypesWithCoordinates>;

      let featureMeasures = {};
      try {
        featureMeasures = getFeatureMeasures(updatedFeature, page, clMap);
      } catch (e) {
        console.error("Error in getFeatureMeasures", e, updatedFeature);
      }

      updatedFeature = {
        ...updatedFeature,
        properties: {
          ...updatedFeature.properties,
          ...featureMeasures,
        },
      };

      return updatedFeature;
    }
  );

  if (afterHook) {
    cleanedFeatures = cleanedFeatures.map(afterHook);
  }

  return cleanedFeatures;
}

// this shouldn't be here
function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("successfully copied to Clipboard!" + msg);
  } catch (err) {
    console.error("Could not copy text: ", err);
  }

  document.body.removeChild(textArea);
}

// same, shouldn't be here
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("successfully copied to Clipboard!");
    },
    (err) => {
      console.error("Could not copy text: ", err);
    }
  );
}

export function cleanFeaturesClassifications(
  features: Feature<FeatureTypesWithCoordinates>[],
  clMap: any
) {
  return features
    .filter((fe) => fe.properties.className !== HOLE_TYPE)
    .map((fe: any) => {
      return {
        ...fe,
        properties: {
          ...fe.properties,
          types: fe.properties.types.filter(Boolean),
          className:
            fe.properties?.className === HOLE_TYPE ||
            fe.properties?.types.includes(GEOJSON_TYPES.markup) ||
            fe.properties?.types.includes(GEOJSON_TYPES.markup) ||
            fe.properties?.types.includes(GEOJSON_TYPES.dimensionLine) ||
            fe?.properties?.className in clMap
              ? fe.properties.className
              : GEOJSON_TYPES_CLASSIFICATIONS[fe.geometry.type],
        },
      };
    });
}

export function cleanMarkupFeatures(
  features: Feature<FeatureTypesWithCoordinates>[]
) {
  return features.map((fe) => {
    if (fe?.properties?.types?.includes(MODES_IDS.markup_text)) {
      const isOldMarkup = !fe?.properties?.bboxFeature;
      const bboxFeature = bboxPolygon(bbox(fe.geometry));

      if (isOldMarkup) {
        return {
          ...fe,
          properties: {
            ...fe.properties,
            bboxFeature,
            border: DEFAULT_MARKUP_COLOR,
            modeId: MODES_IDS.markup_text,
            text: DEFAULT_MARKUP_TEXT_OPTIONS,
          },
        };
      }
      return fe;
    }
    return fe;
  });
}

export function getPolygonHoles(features: any[]) {
  const holesFeatures = features.filter(
    (feature) => feature.properties.className === HOLE_TYPE
  );

  return [
    ...features.reduce((acc, feature) => {
      if (
        feature.geometry.coordinates.length > 1 &&
        feature.geometry.type === GEOJSON_TYPES.Polygon &&
        !feature.properties.types.includes(GEOJSON_TYPES.markup)
      ) {
        const holes = feature.geometry.coordinates.slice(1);

        return [
          ...acc,
          ...holes.map((hole: any, idx: number) => {
            const existingHoleId = holesFeatures.find(
              (holeFeature) =>
                holeFeature.properties.parentId === feature.properties.id &&
                holeFeature.properties.index === idx
            )?.properties.id;

            return {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [hole],
              },
              properties: {
                index: idx,
                className: HOLE_TYPE,
                parentId: feature.properties.id,
                types: feature.properties.types,
                color: feature.properties.color,
                id:
                  existingHoleId ||
                  `${HOLE_TYPE}_${feature.properties.id}_${idx}`,
              },
            };
          }),
        ];
      }
      return acc;
    }, []),
    ...features.filter((feature) => feature.properties.className !== HOLE_TYPE),
  ];
}

export function handlePasteDataAtCenter({
  clMap,
  coords,
  isHole,
  geoJson,
  inplace,
  clipBoardData,
  copiedFeatures,
  isPasteContinuous,
  filteredtakeOffTypes,
}: PASTE_PROPS) {
  const centerPt = center({
    type: GEOJSON_TYPES.FeatureCollection,
    features: copiedFeatures,
  });

  let diffX = 0;
  let diffY = 0;
  if (isPasteContinuous && clipBoardData?.diffX && clipBoardData?.diffY) {
    diffX = clipBoardData.diffX * (clipBoardData?.multiplier || 1);
    diffY = clipBoardData.diffY * (clipBoardData?.multiplier || 1);

    clipBoardData.multiplier = (clipBoardData?.multiplier || 1) + 1;
  } else {
    diffX =
      inplace && coords ? 0 : coords[0] - centerPt.geometry.coordinates[0];
    diffY =
      inplace && coords ? 0 : coords[1] - centerPt.geometry.coordinates[1];

    clipBoardData.diffX = diffX;
    clipBoardData.diffY = diffY;
    clipBoardData.multiplier = 2;
    clipBoardData.coords = centerPt.geometry.coordinates;
  }

  let updatedCopiedFeatures = copiedFeatures
    .map((fe) => {
      const id = generateId();

      return {
        ...fe,
        geometry: {
          ...fe.geometry,
          coordinates:
            fe.geometry.type === GEOJSON_TYPES.Point
              ? [
                  fe.geometry.coordinates[0] + diffX,
                  fe.geometry.coordinates[1] + diffY,
                ]
              : fe.geometry.coordinates.map(function updatePosition(
                  coord: any
                ): any {
                  if (!Array.isArray(coord[0])) {
                    return [coord[0] + diffX, (coord[1] as number) + diffY];
                  } else {
                    return coord.map(updatePosition);
                  }
                }),
        },
        properties: {
          ...fe.properties,
          id,
        },
      };
    })
    .filter((feature) => filterFeatureTypes(feature, filteredtakeOffTypes));

  let ids = updatedCopiedFeatures.map((fe) => fe.properties.id);

  if (isHole && !inplace) {
    ids = [];
    let targetPolygons: any = [];

    updatedCopiedFeatures.forEach((copiedFeature) => {
      const foundPolygons = geoJson.filter((geoJsonFeature) => {
        return (
          geoJsonFeature?.geometry?.type === GEOJSON_TYPES.Polygon &&
          booleanWithin(copiedFeature, geoJsonFeature)
        );
      });

      if (foundPolygons?.length > 0) {
        foundPolygons.forEach((foundPolygon) => {
          if (
            !targetPolygons.find(
              (tp: any) => tp?.properties?.id === foundPolygon?.properties?.id
            )
          ) {
            targetPolygons.push(foundPolygon);
          }
        });
      }
    });

    updatedCopiedFeatures = targetPolygons.map((targetPolygon: any) => {
      const holes = updatedCopiedFeatures.filter((copiedFeature) => {
        return booleanWithin(copiedFeature, targetPolygon);
      });

      holes.forEach((hole: any, idx: number) => {
        ids.push(
          `${HOLE_TYPE}_${targetPolygon.properties.id}_${
            targetPolygon.geometry.coordinates.length - 1 + idx
          }`
        );
      });
      return {
        ...targetPolygon,
        geometry: {
          ...targetPolygon.geometry,
          coordinates: [
            ...targetPolygon.geometry.coordinates,
            ...holes.map((hole: any) => hole.geometry.coordinates[0]),
          ],
        },
      };
    });
  }

  clipBoardData.ids = ids;
  copyTextToClipboard(JSON.stringify(clipBoardData));

  if (clMap) {
    updatedCopiedFeatures = cleanFeaturesClassifications(
      updatedCopiedFeatures,
      clMap
    );
  }

  const newIDS = updatedCopiedFeatures.map((fe) => fe.properties.id);

  return [updatedCopiedFeatures, newIDS, ids];
}
