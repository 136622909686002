import localforage from "localforage";

import {
  idKey,
  setKey,
  pageKey,
  viewKey,
  projectKey,
  organizationKey,
} from "./const";

import { makeUnique } from "sf/utils/array";
import { extendPrototype as extendWithSetItems } from "localforage-setitems";
import { extendPrototype as extendWithGetItems } from "localforage-getitems";
import { extendPrototype as extendWithRemoveItems } from "localforage-removeitems";

extendWithSetItems(localforage);
extendWithGetItems(localforage);
extendWithRemoveItems(localforage);

export async function setLocalItem(prefix, item, update = true) {
  if (!prefix || !item?.id) {
    return null;
  }

  const storageKey = prefix + item.id;

  item = {
    ...item,
    lastUsed: new Date(),
  };

  if (update) {
    item = {
      ...item,
      lastSynced: new Date(),
    };
  }

  try {
    await localforage.setItem(storageKey, item);
    return item;
  } catch (e) {
    console.warn("localforage setLocalItem E : ", e);
    return null;
  }
}

export async function setLocalItems(prefix, items, update = true) {
  if (!prefix || items?.length === 0) {
    return null;
  }

  items = items?.map((i) =>
    update
      ? { ...i, lastUsed: new Date(), lastSynced: new Date() }
      : { ...i, lastUsed: new Date() }
  );
  const storageKeys = items.map((i) => ({ key: prefix + i.id, value: i }));

  try {
    await localforage.setItems(storageKeys);
    return items;
  } catch (e) {
    console.warn("localforage setLocalItems E : ", e);
    return null;
  }
}

export async function getAll() {
  const setsStorage = await localforage.getItem(setKey + idKey);
  const pagesStorage = await localforage.getItem(pageKey + idKey);
  const viewsStorage = await localforage.getItem(viewKey + idKey);
  const projectsStorage = await localforage.getItem(projectKey + idKey);
  const organizationsStorage = await localforage.getItem(
    organizationKey + idKey
  );

  return {
    sets: setsStorage,
    pages: pagesStorage,
    views: viewsStorage,
    projects: projectsStorage,
    organizations: organizationsStorage,
  };
}

export async function getLocalItem(prefix, id) {
  if (!prefix || !id) {
    return null;
  }

  const storageKey = prefix + id;

  try {
    const item = await localforage.getItem(storageKey);
    return item;
  } catch (e) {
    console.warn("localforage getLocalItem E : ", e);
    return null;
  }
}

export async function getLocalItems(prefix, ids = []) {
  if (!prefix || ids?.length === 0) {
    return null;
  }

  const storageKeys = ids.map((id) => prefix + id);

  try {
    const items = await localforage.getItems(storageKeys);
    return Object.keys(items).map((k) => items[k]);
  } catch (e) {
    console.warn("localforage getLocalItems E : ", e);
    return null;
  }
}

export async function removeItem(prefix, id) {
  if (!prefix || !id) {
    return null;
  }

  const storageKey = prefix + id;

  try {
    await localforage.removeItem(storageKey);
    return storageKey;
  } catch (e) {
    console.warn("localforage removeItem E : ", e);
    return null;
  }
}

export async function setSyncingValue(key) {
  const newSyncDate = new Date(Date.now() - 10000).toISOString();
  try {
    await localforage.setItem(key, newSyncDate);
  } catch (e) {
    console.warn("localforage setSyncingValue E : ", e);
  }
}

export async function getSyncingValue(key) {
  try {
    const value = await localforage.getItem(key);
    return value;
  } catch (e) {
    console.warn("localforage getSyncingValue E : ", e);
    return null;
  }
}

export async function clearSyncingValue(key) {
  try {
    await localforage.removeItem(key);
  } catch (e) {
    console.warn("localforage clearSyncingValue E : ", e);
  }
}

export async function getGlobalSync() {
  try {
    const value = await localforage.getItem("global_sync_time");
    return value;
  } catch (e) {
    console.warn("localforage getGlobalSync E : ", e);
    return null;
  }
}

export async function setGlobalSync() {
  const newSyncDate = new Date(Date.now()).toISOString();
  try {
    await localforage.setItem("global_sync_time", newSyncDate);
  } catch (e) {
    console.warn("localforage setGlobalSync E : ", e);
  }
}

async function storeAllLocally(storageKey, newValue) {
  try {
    const existingValue = await localforage.getItem(storageKey);

    if (existingValue && existingValue.length > 0) {
      const storageValue = makeUnique([...existingValue, ...newValue]);
      await localforage.setItem(storageKey, storageValue);
      return storageKey;
    } else {
      const storageValue = newValue;
      await localforage.setItem(storageKey, storageValue);
      return storageKey;
    }
  } catch (e) {
    console.warn("storeAllLocally E : ", e);
  }

  return null;
}

/**
 *
 * @param {string} storageKey Storage key used to store the list used for queries
 * @param {string} storeKey storage key specific to the store, ex: organization....,
 * @param {[string]} filterKey key used in filtering
 */
export async function setStore(data, storageKey, storeKey, filterKey = null) {
  if (!data?.length || data?.length === 0) return null;

  try {
    const storedData = await setLocalItems(storeKey, data, true);

    const newValue = storedData?.map((item) => ({
      id: item?.id,
      [filterKey]: item[filterKey],
    }));
    return await storeAllLocally(storageKey, newValue);
  } catch (e) {
    console.warn("storeAllLocally setStore E : ", e);
  }
}

/**
 *
 * @param {string} storageKey Storage key used to store the list used for queries
 * @param {string} storeKey storage key specific to the store, ex: organization....,
 * @param {[string]} filterKey key used in filtering
 * @param {any} props extra props
 * @returns
 */
export async function getAllLocalStore(
  storageKey,
  storeKey,
  filterKey = null,
  props = {}
) {
  try {
    const ids = await localforage.getItem(storageKey);

    if (ids?.length > 0) {
      const filteredIds = filterKey
        ? ids.filter((item) => item[filterKey] === props[filterKey])
        : ids;

      const data = await Promise.all(
        filteredIds?.map((idVal) => getLocalItem(storeKey, idVal.id))
      );

      return data;
    } else {
      return null;
    }
  } catch (e) {
    console.warn("localforage getAllLocalStore E : ", e);
    return null;
  }
}

export async function clearAllLocalStore(
  storageKey,
  storeKey,
  filterKey = null,
  props = {}
) {
  try {
    const ids = await localforage.getItem(storageKey);
    if (ids?.length > 0) {
      const filteredIds = filterKey
        ? ids.filter((item) => item[filterKey] === props[filterKey])
        : ids;

      const newValue = ids.filter((item) =>
        filterKey ? item[filterKey] !== props[filterKey] : true
      );

      if (filteredIds?.length > 0) {
        const keys = filteredIds?.map((idVal) => storeKey + idVal.id);
        await localforage.removeItems(keys);
      }

      await localforage.setItem(storageKey, newValue);
    }
  } catch (e) {
    console.warn("localforage clearAllLocalStore E : ", e);
  }

  return null;
}

export async function clearIdsFromLocalStore(
  storageKey,
  storeKey,
  deleteIds = []
) {
  const deleteIdsSet = new Set(deleteIds);

  try {
    const ids = await localforage.getItem(storageKey);

    if (ids?.length > 0) {
      const filteredIds = ids.filter((item) => deleteIdsSet.has(item?.id));
      if (filteredIds?.length > 0) {
        const keys = filteredIds?.map((item) => storeKey + item.id);
        await localforage.removeItems(keys);
      }

      const newValue = ids.filter((item) => !deleteIdsSet.has(item?.id));
      if (newValue) {
        await localforage.setItem(storageKey, newValue);
      }
    }
  } catch (e) {
    console.warn("localforage clearAllLocalStore E : ", e);
  }

  return null;
}

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  _____________________________________________ ORGANIZATIONS SPECIFIC
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export async function setOrgIds(orgs) {
  const storageKey = organizationKey + idKey;
  return setStore(orgs, storageKey, organizationKey);
}

export async function getAllLocalOrgs() {
  const storageKey = organizationKey + idKey;
  return getAllLocalStore(storageKey, organizationKey);
}

export async function clearAllLocalOrgs() {
  const storageKey = organizationKey + idKey;
  return clearAllLocalStore(storageKey, organizationKey);
}

export async function clearOrgsById(ids = []) {
  const storageKey = organizationKey + idKey;
  return clearIdsFromLocalStore(storageKey, organizationKey, ids);
}
/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  __________________________________________________ PROJECTS SPECIFIC
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export async function setProjectIds(projects) {
  const storageKey = projectKey + idKey;
  return setStore(projects, storageKey, projectKey, "organization_id");
}

export async function getAllLocalProjects(props) {
  const storageKey = projectKey + idKey;
  return getAllLocalStore(storageKey, projectKey, "organization_id", props);
}

export async function clearAllLocalProjects(props) {
  const storageKey = projectKey + idKey;
  return clearAllLocalStore(storageKey, projectKey, "organization_id", props);
}

export async function clearProjectsById(ids = []) {
  const storageKey = projectKey + idKey;
  return clearIdsFromLocalStore(storageKey, projectKey, ids);
}

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  _____________________________________________________ VIEWS SPECIFIC
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export async function setViewsIds(views) {
  const storageKey = viewKey + idKey;
  return setStore(views, storageKey, viewKey, "set_id");
}

export async function getAllLocalViews(props) {
  const storageKey = viewKey + idKey;
  return getAllLocalStore(storageKey, viewKey, "set_id", props);
}

export async function clearAllLocalViews(props) {
  const storageKey = viewKey + idKey;

  return clearAllLocalStore(storageKey, viewKey, "set_id", props);
}

export async function clearViewsById(ids = []) {
  const storageKey = viewKey + idKey;
  return clearIdsFromLocalStore(storageKey, viewKey, ids);
}

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  ______________________________________________________ SETS SPECIFIC
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export async function setSetsIds(sets) {
  const storageKey = setKey + idKey;
  return setStore(sets, storageKey, setKey, "project_id");
}

export async function getAllLocalSets(props) {
  const storageKey = setKey + idKey;
  return getAllLocalStore(storageKey, setKey, "project_id", props);
}

export async function clearAllLocalSets(props) {
  const storageKey = setKey + idKey;
  return clearAllLocalStore(storageKey, setKey, "project_id", props);
}

export async function clearSetsById(ids = []) {
  const storageKey = setKey + idKey;
  return clearIdsFromLocalStore(storageKey, setKey, ids);
}

/**
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 *  ______________________________________________________ PAGES SPECIFIC
 *  ____________________________________________________________________
 *  ____________________________________________________________________
 */

export async function setPagesIds(pages) {
  const storageKey = pageKey + idKey;
  return setStore(pages, storageKey, pageKey, "set_id");
}

export async function getAllLocalPages(props) {
  const storageKey = pageKey + idKey;
  return getAllLocalStore(storageKey, pageKey, "set_id", props);
}

export async function clearAllLocalPages(props) {
  const storageKey = pageKey + idKey;
  return clearAllLocalStore(storageKey, pageKey, "set_id", props);
}

export async function clearPagesById(ids = []) {
  const storageKey = pageKey + idKey;
  return clearIdsFromLocalStore(storageKey, pageKey, ids);
}

export async function getLocalPageById(id) {
  return getLocalItem(pageKey, id);
}

export async function getLocalPagesById(ids) {
  return getLocalItems(pageKey, ids);
}
