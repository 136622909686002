import moment from "moment";
// @ts-ignore Types not available
import * as mupdfjs from "mupdf/mupdfjs";
// @ts-ignore Types not available
import type { PDFDocument } from "mupdf/mupdfjs";

import * as viewApi from "src/lib/api/view";
import * as pageApi from "src/lib/api/page";

import { MIME_TYPES } from "src/constants/file";
import { VIEW_PREFIX } from "sf/permissions";

type UntypedPDFPage = any;
type SourcePDF = {
  file: File;
  error?: Error;
  id: string;
  isPDF: boolean;
  isUploaded: boolean;
  isUploading: boolean;
  name: string;
  numberOfPages: number;
  pageNames: string[];
  pdf: PDFDocument;
  progress: number;
  projectId: string;
  selectedPages: number[];
  setId: string;
  size: number;
  type: string;
};

// from https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
export function dataURLtoFile(
  dataurl: string,
  filename: string
): File {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function byteArrayToFile(
  byteArray: Uint8Array,
  filename: string,
  type: string
) {
  return new File([byteArray], filename, { type });
}

async function splitFile(
  sourcePDF: SourcePDF
): Promise<File[]> {
  const sourceAsBinary = await sourcePDF.file.arrayBuffer();
  const document: PDFDocument = mupdfjs.PDFDocument.openDocument(sourceAsBinary, MIME_TYPES.PDF);

  const documents: PDFDocument[] = document.split(Array.from({ length: document.countPages() }, (_, i) => i));
  const files: File[] = [];
  let index = 0;

  for (const doc of documents) {
    const docBuffer = doc
      .saveToBuffer()
      .asUint8Array();

    const pageName = `${sourcePDF.pageNames[index].replace(/[\x00-\x1F]/g, "").toString()}.pdf`;

    files.push(
      byteArrayToFile(docBuffer, pageName, MIME_TYPES.PDF)
    );

    index++;
  }

  return files;
}

export function uploadFile(
  url: string,
  file: File,
  onProgress: (progress: string) => void
) {
  return new Promise<void>((resolve, reject) => {
    let done = false;

    const request = new XMLHttpRequest();
    request.open("PUT", url);

    const abort = async (error) => {
      done = true;
      reject(error);
    };

    request.onerror = function (error) {
      if (done) return;
      abort(error);
    };

    request.upload.onprogress = (e) => {
      if (done) return;
      if (onProgress) {
        onProgress(((e.loaded / e.total) * 100).toFixed(2));
      }
    };

    request.onload = function () {
      if (done) return;

      done = true;

      if (request.status >= 200 && request.status < 300) {
        resolve();
      } else {
        abort(request);
      }
    };

    request.send(file);
  });
}

export async function processFiles(
  filteredUploads: Map<string, SourcePDF>,
  uploads: Map<string, SourcePDF>,
  pagesState: Map<string, UntypedPDFPage>,
  removedPages: Map<string, number[]>
) {
  const uploadsCopy = new Map<string, SourcePDF>(uploads);
  const pagesStateCopy = new Map<string, UntypedPDFPage>(pagesState);

  for (const [, file] of filteredUploads) {
    const pages = await splitFile(file);

    for (const [pageIndex, pageName] of file.pageNames.entries()) {
      const pageId = `${file.id}-${pageIndex}`;

      if (!pagesStateCopy.has(pageId)) {
        if (
          (removedPages.has(file.id) &&
            removedPages &&
            removedPages.get(file?.id).includes(pageIndex)) ||
          (pagesStateCopy.has(pageId) && pagesStateCopy.get(pageId).pageFile)
        ) {
          if (pagesStateCopy.has(pageId)) {
            pagesStateCopy.set(pageId, {
              ...pagesStateCopy.get(pageId),
              isUploading: true,
            });
          }
        } else {
          let pageFile = null;
          try {
            if (file.isPDF && file.numberOfPages > 1) {
              pageFile = pages[pageIndex];
            } else {
              pageFile = file.file;
            }

            const currentPage = pagesStateCopy.get(pageId);

            pagesStateCopy.set(pageId, {
              id: file.id,
              pageId,
              pageName,
              pageIndex,
              pageFile,
              isUploading: true,
              isUploaded: currentPage?.isUploaded || false,
              progress: currentPage?.progress || 0,
              error: currentPage?.error || null,
            });
          } catch (e) {
            if (file.numberOfPages <= 1) {
              if (uploadsCopy.has(file.id)) {
                uploadsCopy.set(file.id, {
                  ...uploadsCopy.get(file.id),
                  error: e,
                });
              }
            }

            pagesStateCopy.set(pageId, {
              id: file.id,
              pageId,
              pageName,
              pageIndex,
              pageFile,
              isUploading: false,
              isUploaded: false,
              progress: 0,
              error: e,
            });
          }
        }
      } else {
        pagesStateCopy.set(pageId, {
          ...pagesStateCopy.get(pageId),
          isUploading: true,
        });
      }
    }
  }

  return { uploads: uploadsCopy, pagesState: pagesStateCopy };
}

export async function createPageRecord(
  projectId: string,
  setId: string,
  folderId: string,
  page: UntypedPDFPage,
  showError: (error: any) => void
) {
  let pageCopy = { ...page };

  try {
    let apiPage = await pageApi.create({
      set_id: setId,
      project_id: projectId,
      index: page.pageIndex + 1,
      page_folder_id: folderId || null,
      original_mime: page.pageFile.type,
      name:
        page.pageFile.name.length > 135
          ? page.pageFile.name.substring(0, 110)
          : page.pageFile.name,
      received_date: moment().format("YYYY-MM-DD"),
      drawing_date: moment().format("YYYY-MM-DD"),
    });

    if (!pageCopy.apiPage) {
      pageCopy = { ...pageCopy, apiPage };
    }
  } catch (e) {
    const error =
      e?.message ||
      JSON.stringify(e) ||
      e ||
      "An unexpected error has occured while creating the page record";
    showError({
      children: error,
    });

    pageCopy = { ...pageCopy, apiPage: null, error };
  }

  return pageCopy;
}

export async function uploadPage(
  page: UntypedPDFPage,
  onProgress?: (progress: string, page: UntypedPDFPage) => void,
  showError?: (error: any) => void
) {
  let pageCopy = { ...page };

  try {
    await uploadFile(page.apiPage.original_signed, page.pageFile, (value) => {
      if (onProgress) {
        onProgress(value, { ...pageCopy, progress: value });
      }
    });

    pageCopy = {
      ...pageCopy,
      isUploaded: true,
      progress: 100,
    };
  } catch (e) {
    if (pageCopy?.apiPage?.id) {
      try {
        pageApi.remove(pageCopy.apiPage.id);
      } catch (err) {
        //ignore
      }
    }

    const error =
      e?.message ||
      JSON.stringify(e) ||
      e ||
      "An unexpected error has occured while uploading the page";

    if (showError) {
      showError({
        children: error,
      });
    }

    pageCopy = {
      ...pageCopy,
      apiPage: null,
      isUploaded: false,
      error,
    };
  }

  return pageCopy;
}

export async function updatePageState(
  page: UntypedPDFPage,
  folderId: string,
  showError: (error: any) => void
) {
  let pageCopy = { ...page };

  try {
    await pageApi.update({
      ...pageCopy.apiPage,
      active: true,
      page_folder_id: folderId || null,
    });

    pageCopy = {
      ...pageCopy,
      apiPage: { ...pageCopy.apiPage, active: true },
    };
  } catch (e) {
    if (pageCopy?.apiPage?.id) {
      try {
        await pageApi.remove(pageCopy.apiPage.id);
      } catch (err) {
        //ignore
      }
    }

    const error =
      e?.message ||
      JSON.stringify(e) ||
      e ||
      "An unexpected error has occured while updating page state";
    showError({
      children: error,
    });

    pageCopy = {
      ...pageCopy,
      apiPage: null,
      isUploaded: false,
      error,
    };
  }

  return pageCopy;
}

export async function createPageView(
  setId: string,
  page: UntypedPDFPage,
  userName: string,
  showError: (error: any) => void,
  syncPage: (page: UntypedPDFPage) => void
) {
  let pageCopy = { ...page };

  try {
    await viewApi.create({
      name: userName + VIEW_PREFIX,
      set_id: setId,
      page_id: pageCopy.apiPage.id,
    });
  } catch (e) {
    const error =
      e?.message ||
      JSON.stringify(e) ||
      e ||
      "An unexpected error has occured while updating page state";
    showError({
      children: error,
    });

    pageCopy = {
      ...pageCopy,
      error,
    };
  }

  if (pageCopy?.apiPage) {
    await syncPage({
      item: pageCopy?.apiPage,
      isLocalOnly: false,
      isAdd: true,
    });
  }

  return pageCopy;
}
