import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import { useLocalStorage } from "react-use";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import getLocaleMessages from "sf/i18n";
import useService from "sf/services/useService";
import AnalyticsService from "sf/services/AnalyticsService";
import { ADMIN_ROLE, SUPERADMIN_ROLE, getPermissions } from "sf/permissions";

import { msalConfig } from "./msConfig";
import { usePrevious } from "sf/hooks/usePrevious";
import { ROLE_USER } from "src/components/ChatGPTUI/const";
import {
  fetchUser,
  updateUser,
  fetchUserOrg,
  DEFAULT_USAGE,
  fetchUserUsage,
  fetchUserInvites,
  fetchOrgExternalUsers,
  fetchOrgUsersAndInvites,
} from "./utils";
import useSubscription from "./hooks/useSubscription";

const AuthContext = createContext({});
const msalInstance = new PublicClientApplication(msalConfig);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const analyticsService = useService(AnalyticsService);

  const [user, setUser] = useLocalStorage("togal-user", null);
  const [userUsage, setUserUsage] = useLocalStorage("togal-user-usage", null);
  const [selectedOrg, setSelectedOrg] = useLocalStorage("user-org", null);
  const [superAdminOrganizationId, setSuperAdminOrganizationId] =
    useLocalStorage("togal-superadminOrgId", null);

  const [isMobile, setIsMobile] = useState(false);
  const [isLoadingAuth, setLoading] = useState(true);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [sessionId, setSessionId] = useLocalStorage("togal-sessionId", null);

  const subscriptionProps = useSubscription({
    user,
    organization: selectedOrg,
  });

  const previousSuperAdminOrganizationId = usePrevious(
    superAdminOrganizationId
  );

  const locales = useMemo(() => getLocaleMessages(), [user]);

  const isSuperAdmin = useMemo(() => user?.type === SUPERADMIN_ROLE, [user]);
  const permissions = useMemo(
    () => getPermissions(user, selectedOrg),
    [user, selectedOrg]
  );

  const isOnEssentialsPlan = useMemo(
    () =>
      subscriptionProps?.subscriptionState?.isOnEssentialsPlan &&
      !subscriptionProps?.subscriptionState?.trialProps?.isTrial,
    [subscriptionProps]
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!user || user.type !== SUPERADMIN_ROLE) {
      setSuperAdminOrganizationId(null);
    }
  }, [user, setSuperAdminOrganizationId]);

  // On load, if a user is set, fetch latest.
  useEffect(() => {
    async function getUserData() {
      setLoading(true);
      try {
        const fetchedUser = await fetchUser(user.id);
        const orgID = user?.organization_id || superAdminOrganizationId;
        const usage = await fetchUserUsage();

        setUserUsage(usage);

        let isOrgSet = false;
        if (orgID) {
          const org = await fetchUserOrg(orgID);
          setUser({
            ...fetchedUser,
            org,
          });

          if (
            selectedOrg?.id === orgID ||
            !selectedOrg?.id ||
            ![
              user?.organization_id || "",
              ...(user?.organization_ids || []),
            ].includes(selectedOrg?.id)
          ) {
            isOrgSet = true;
            setSelectedOrg(org);
          }
        } else {
          setUser(fetchedUser);
        }

        if (!isOrgSet && selectedOrg?.id) {
          try {
            const updatedOrgUsers = await fetchOrgUsersAndInvites(
              selectedOrg?.id
            );
            const externalUsers = await fetchOrgExternalUsers(selectedOrg?.id);

            setSelectedOrg({
              ...selectedOrg,
              users: updatedOrgUsers || [],
              externalUsers: externalUsers || [],
            });
          } catch (error) {
            console.error("Error fetching org", error);
          }
        }

        const invites = await fetchUserInvites(fetchedUser);
        setPendingInvites(invites || []);
      } catch (error) {
        console.error("Error fetching user on load", error);
        signOut();
      }

      setLoading(false);
    }

    if (
      !user ||
      superAdminOrganizationId === previousSuperAdminOrganizationId
    ) {
      setLoading(false);
      return;
    }

    getUserData();
  }, [superAdminOrganizationId, previousSuperAdminOrganizationId]);

  const signOut = useCallback(() => {
    setUser(null);
    setSessionId(null);
    setSelectedOrg(null);
    setSuperAdminOrganizationId(null);

    window.location.reload();
  }, [setUser, setSessionId]);

  const fetchLatestUserUsage = useCallback(async () => {
    const usage = await fetchUserUsage();
    setUserUsage(usage);
    return usage;
  }, [user?.id]);

  const fetchLatestSelectedOrg = useCallback(async () => {
    try {
      const updatedOrg = await fetchUserOrg(selectedOrg?.id);
      setSelectedOrg(updatedOrg);
    } catch (error) {
      const orgUsers = await fetchOrgUsersAndInvites(selectedOrg?.id);
      const externalUsers = await fetchOrgExternalUsers(selectedOrg?.id);

      setSelectedOrg({
        ...selectedOrg,
        users: orgUsers || [],
        externalUsers: externalUsers || [],
      });
    }
  }, [selectedOrg]);

  const sendUserEvent = useCallback(
    async (event) => {
      if (!(event in (user?.events || {}))) {
        const org = user?.org || null;
        const newUser = await updateUser({
          ...user,
          events: {
            ...user?.events,
            [event]: new Date(Date.now()).toISOString(),
          },
        });
        if (org) {
          newUser.org = org;
        }
        setUser(newUser);

        analyticsService.sendEvent(event);
      }
    },
    [user]
  );

  useEffect(() => {
    // NOTE Prevent organization users from desynchronizing and keeping stale data
    if (!isSuperAdmin) {
      fetchLatestSelectedOrg();
    }
  }, [user, isSuperAdmin]);

  const value = (window.authContext = useMemo(
    () => ({
      ...subscriptionProps,
      user: {
        ...user,
        type:
          user?.type === ADMIN_ROLE && user?.organization_id !== selectedOrg?.id
            ? ROLE_USER
            : user?.type,
      },
      locales,
      isMobile,
      sessionId,
      permissions,
      selectedOrg,
      isSuperAdmin,
      isLoadingAuth,
      pendingInvites,
      isOnEssentialsPlan,
      isAuthenticated: !!user,
      superAdminOrganizationId,
      userUsage: userUsage || DEFAULT_USAGE,
      isAdmin:
        user?.type === ADMIN_ROLE && user?.organization_id === selectedOrg?.id,

      setUser,
      signOut,
      setUserUsage,
      setSessionId,
      sendUserEvent,
      setSelectedOrg,
      setPendingInvites,
      fetchLatestUserUsage,
      fetchLatestSelectedOrg,
      setSuperAdminOrganizationId,
    }),
    [
      user,
      locales,
      isMobile,
      sessionId,
      userUsage,
      permissions,
      selectedOrg,
      isLoadingAuth,
      pendingInvites,
      subscriptionProps,
      superAdminOrganizationId,

      setUser,
      signOut,
      setSessionId,
      sendUserEvent,
      setSelectedOrg,
      setPendingInvites,
      fetchLatestUserUsage,
      fetchLatestSelectedOrg,
      setSuperAdminOrganizationId,
    ]
  ));

  return (
    <MsalProvider instance={msalInstance}>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </MsalProvider>
  );
};

export * from "./utils";
