import { COMMON_MESSAGES } from "./common";

export const PROJECTS_MESSAGES = {
  ...COMMON_MESSAGES,

  DELETE_PROJECT: "Delete project",
  DELETE_PROJECTS: "Delete projects",
  DELETE_PROJECT_DESCRIPTION:
    "By performing this action, all files, sets, and drawings under this project will be permanently deleted.",
  DELETE_PROJECTS_DESCRIPTION:
    "By performing this action, all files, sets, and drawings under these projects will be permanently deleted.",

  NEW_PROJECT: "New project",
  EDIT_PROJECT: "Edit project",

  FILTER_ALL_PROJECTS: "All projects",
  FILTER_PROJECTS_STARRED: "Starred",
  FILTER_PROJECTS_MINE: "My projects",
  FILTER_PROJECTS_SHARED: "Shared with me",
  FILTER_PROJECTS_ARCHIVED: "Archived",

  TM_STAR: "An unexpected error has occured while starring the project",
  TM_DUPLICATE:
    "An unexpected error has occured while trying to duplicate the project",
  TM_ARCHIVE:
    "An unexpected error has occured while trying to archive the project",
  TM_PERMISSION:
    "You don't have the required permission to perform this action",
  TM_DELETE:
    "An unexpected error has occured while trying to delete the project",
  TM_CREATE:
    "An unexpected error has occured while trying to create the project",
  TM_EDIT: "An unexpected error has occured while trying to edit the project",
  TM_CONFLICT: "A project with this name already exists",
  TM_CREATE_FOLDER:
    "An unexpected error has occured while trying to create a folder",
  TM_EDIT_FOLDER:
    "An unexpected error has occured while trying to edit a folder",
  TM_DELETE_FOLDER:
    "An unexpected error has occured while trying to delete a folder",

  PROJECT_NAME_PLACEHOLDER: "Enter project name",
  PROJECT_UNIT_PLACEHOLDER: "Choose a project unit",
  LONG_NAME_ERROR: "Project Name cannot be longer than 256 characters",

  MEASUREMENT_SYSTEM: "Default measurement system",
  MEASUREMENT_SYSTEM_DROPDOWN: "Search measurement",

  HEADER_CREATED_BY: "Created by",
  HEADER_USERS: "Users",
  HEADER_CREATED_ON: "Created on",
  HEADER_LAST_MODIFIED: "Last modified",
};
